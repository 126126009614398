// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"dVB5qYSJH_7q8I7o7ZEH8"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

const environment = process.env.NEXT_PUBLIC_TARGET_ENV;

if (environment === "production" || environment === "staging" || environment === "development") {
  Sentry.init({
    dsn: "https://25d2ffc8042b916295dafee20b139b65@o250275.ingest.us.sentry.io/4507408087711749",

    tracesSampleRate: 0.01,
    sampleRate: 1.0,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,
    environment: process.env.NEXT_PUBLIC_REGION_ENV,
    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      // Sentry.replayIntegration({
      //   // Additional Replay configuration goes in here, for example:
      //   maskAllText: true,
      //   blockAllMedia: true,
      // }),
    ],
  });
}
